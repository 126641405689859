<template>
  <div id="component-list-center">
    <div class="card card-border">
      <div class="card-header p-0 bg-white">
        <div class="ml-3 mt-3 mb-3">
          <h5>Choisir un centre :</h5>
        </div>
      </div>
      <div class="card-body">
        <div v-for="(center, index) in CenterModule.centers" :key="index">
          <div
              class="d-flex p-2 c-pointer"
              :class="(selectCenter && selectCenter === center.id) ? 'center-active' : ''"
              v-on:click="emitSelectCenter"
              :data-id="center.id"
          >
            <b-icon
                icon="building"
                font-scale="1.5"
                variant="primary"
                class="icon-folder mr-2"
            ></b-icon>
            {{ center.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    selectCenter: Number,
  },
  methods: {
    emitSelectCenter($event) {
      this.$emit('emit-select-center', $event)
    },
  },
  computed: {
    ...mapState(['CenterModule']),
  },
}
</script>

<style scoped>

</style>
